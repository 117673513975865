import { useEffect } from "react";
import _find from "lodash/find";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { FileUploaderInput, RadioGroup, Title } from "components";
import { AttachmentIcon } from "assets";
import { Container, FormLayout, ImageHint, Text } from "./styles";
import { usePostAnyDocs } from "features/connection-form/api/post-AnyDocs";
import { useGlobalData } from "hooks";
import { isValidCnpj, onlyNumbers } from "utils";
import { compareTwoStrings } from "string-similarity";

const SOCIAL_CONTRACT_TYPE = "socialContract";

const DOCUMENT_OPTIONS = [
  {
    label: "Contrato social",
    value: "SOCIAL_CONTRACT",
  },
  {
    label: "ATA",
    value: "ATA",
  },
];

type Administrator = {
  admin: string;
  cpf: string;
  endereco: {
    rua: string;
    numero: string;
    bairro: string;
    complemento: string;
    cep: string;
    cidade: string;
    estado: string;
  };
};

type SocialContractData = {
  administradores: Administrator[];
  cnpj: string;
  nire: string;
  filiais: string[];
};

export function UploadSocialContractForm() {
  const { mutate: onAnalyzeSocialContract } = usePostAnyDocs();
  const {
    storedSocialContract,
    setIsLoading,
    accountType,
    setStoredSocialContract,
    setCurrentStep,
  } = useGlobalData();
  const { watch, resetField, reset, getValues } = useFormContext();
  const [name, personCpf, socialContract, companyCnpj, companyFileType] = watch(
    ["nome", "cpf_cnpj", "caminhocontratosocial", "cnpj", "companyFileType"]
  );

  const findAdministrator = (
    administrators: Administrator[]
  ): Administrator | undefined => {
    const cleanedPersonCpf = onlyNumbers(personCpf);
    const cpfMatch = administrators.find(
      (admin) => admin.cpf === cleanedPersonCpf
    );

    if (cpfMatch) return cpfMatch;

    return administrators.find(
      (admin) => compareTwoStrings(admin.admin, name) >= 0.4
    );
  };

  const handleSuccess = (data: SocialContractData) => {
    const adapter = data.administradores.map((item) => ({
      ...item,
      cpf: onlyNumbers(item.cpf),
    }));
    const companyAdm = findAdministrator(adapter);
    const filials = data.filiais.map((item) => onlyNumbers(item));
    const cnpjMismatch =
      !!data.cnpj &&
      isValidCnpj(data?.cnpj) &&
      onlyNumbers(data.cnpj) !== onlyNumbers(companyCnpj) &&
      !filials.includes(onlyNumbers(companyCnpj));

    if (cnpjMismatch) {
      return showErrorAndReset(
        "O Contrato Social anexado não pertence ao CNPJ informado. Por favor, verifique o documento e/ou se o CNPJ informado está correto e tente novamente."
      );
    }

    if (!companyAdm) {
      return showErrorAndReset(
        "Os dados pessoais da pessoa fisíca informados anteriormente estão diferentes do administrador citado no Contrato Social."
      );
    }

    finalizeSuccessProcess(data);
  };

  const showErrorAndReset = (errorMessage: string) => {
    setIsLoading(false);
    resetField("caminhocontratosocial");
    toast.error(errorMessage, {
      position: "bottom-center",
      duration: 10000,
    });
  };

  const finalizeSuccessProcess = (data: SocialContractData) => {
    reset({
      ...getValues(),
      nire: data.nire,
    });

    setStoredSocialContract(socialContract);
    setIsLoading(false);
    toast.success("Documento analisado com sucesso!", {
      position: "bottom-center",
    });
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleError = ({ response: errorResponse }: any) => {
    const customErrorMessage =
      errorResponse?.data?.error?.info ??
      "Erro ao processar o arquivo. Certifique-se de que anexou um documento válido e legível. Tente novamente!";

    toast.error(customErrorMessage, {
      duration: 10000,
    });
    setIsLoading(false);
    resetField("caminhocontratosocial");
  };

  useEffect(() => {
    if (
      !socialContract ||
      socialContract === storedSocialContract ||
      companyFileType === "ATA"
    )
      return;

    const base64Parts = socialContract.split("base64,");
    const data = base64Parts[1];

    if (!data) return;

    setIsLoading(true);

    onAnalyzeSocialContract(
      {
        type: SOCIAL_CONTRACT_TYPE,
        file: data,
        accountType,
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    );
  }, [socialContract]);

  return (
    <Container>
      <Title>Também precisamos do Contrato Social ou ATA da sua empresa</Title>

      <FormLayout>
        <Text>Selecione qual o tipo de documento que será anexado:</Text>
        <RadioGroup name="companyFileType" options={DOCUMENT_OPTIONS} />
      </FormLayout>

      {companyFileType && (
        <>
          <FormLayout>
            <FileUploaderInput
              name="caminhocontratosocial"
              icon={<AttachmentIcon />}
              description={
                companyFileType === "SOCIAL_CONTRACT"
                  ? "Anexar Contrato Social"
                  : "Anexar ATA"
              }
              accept=".pdf"
            />
          </FormLayout>
          <ImageHint>
            Este documento é exclusivamente em PDF <span>*</span>
          </ImageHint>
        </>
      )}
    </Container>
  );
}
