import { createContext, useState, ReactNode } from "react";

interface Address {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  estado: string;
  numero: string;
  rua: string;
}
export interface EnergyBillAnalysis {
  concessionaria: string;
  valorcontribuicao: string;
  valorkwh: string;
  classificacao: string;
  energyBox: string;
  numcliente: string;
  numinstalacao: string;
  consumomedio: string;
  nome: string;
  elegibilidade: {
    elegibility: boolean;
    messages: string[];
  };
  endereco: Address;
  cpfCnpj: string;
  openDebits: boolean;
  needManualAnalysis: boolean;
}

interface GlobalDataContextValue {
  socketId: string;
  setSocketId: React.Dispatch<React.SetStateAction<string>>;
  accountType: "PF" | "PJ";
  setAccountType: React.Dispatch<React.SetStateAction<"PF" | "PJ">>;
  customer: any;
  setCustomer: React.Dispatch<React.SetStateAction<any>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  formState: "start" | "fill" | "sent" | "error";
  setFormState: React.Dispatch<
    React.SetStateAction<"start" | "fill" | "sent" | "error">
  >;
  averageConsumption: string;
  setAverageConsumption: React.Dispatch<React.SetStateAction<string>>;
  billElegibility: boolean;
  setBillElegibility: React.Dispatch<React.SetStateAction<boolean>>;
  globalEnergyBill: string;
  setGlobalEnergyBill: React.Dispatch<React.SetStateAction<string>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  eligibilityErrors: string[];
  setEligibilityErrors: React.Dispatch<React.SetStateAction<string[]>>;
  customerIdFromEnergyBill: string;
  setCustomerIdFromEnergyBill: React.Dispatch<React.SetStateAction<string>>;
  storedCnpjCard: string;
  setStoredCnpjCard: React.Dispatch<React.SetStateAction<string>>;
  storedSocialContract: string;
  setStoredSocialContract: React.Dispatch<React.SetStateAction<string>>;
  energyBillAnalysisResult: EnergyBillAnalysis | null;
  setEnergyBillAnalysisResult: React.Dispatch<
    React.SetStateAction<EnergyBillAnalysis | null>
  >;
  documentsProgress: number;
  setDocumentsProgress: React.Dispatch<React.SetStateAction<number>>;
  needManualAnalysis: boolean;
  setNeedManualAnalysis: React.Dispatch<React.SetStateAction<boolean>>;
}

type GlobalDataProviderProps = {
  children: ReactNode;
};

export const GlobalDataContext = createContext<GlobalDataContextValue>({
  socketId: "",
  setSocketId: () => null,
  accountType: "PF",
  setAccountType: () => null,
  customer: null,
  setCustomer: () => null,
  isLoading: false,
  setIsLoading: () => null,
  averageConsumption: "",
  setAverageConsumption: () => null,
  formState: "start",
  setFormState: () => null,
  billElegibility: false,
  setBillElegibility: () => null,
  globalEnergyBill: "",
  setGlobalEnergyBill: () => null,
  currentStep: 0,
  setCurrentStep: () => null,
  eligibilityErrors: [],
  setEligibilityErrors: () => null,
  customerIdFromEnergyBill: "",
  setCustomerIdFromEnergyBill: () => null,
  storedCnpjCard: "",
  setStoredCnpjCard: () => null,
  storedSocialContract: "",
  setStoredSocialContract: () => null,
  energyBillAnalysisResult: null,
  setEnergyBillAnalysisResult: () => null,
  documentsProgress: 0,
  setDocumentsProgress: () => null,
  needManualAnalysis: false,
  setNeedManualAnalysis: () => null
});

export const GlobalDataProvider = ({ children }: GlobalDataProviderProps) => {
  const [socketId, setSocketId] = useState<string>("");
  const [accountType, setAccountType] = useState<"PF" | "PJ">("PF");
  const [customer, setCustomer] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [averageConsumption, setAverageConsumption] = useState<string>("");
  const [formState, setFormState] = useState<
    "start" | "fill" | "sent" | "error"
  >("start");
  const [billElegibility, setBillElegibility] = useState<boolean>(false);
  const [globalEnergyBill, setGlobalEnergyBill] = useState<string>("");
  const [storedCnpjCard, setStoredCnpjCard] = useState<string>("");
  const [currentStep, setCurrentStep] = useState(0);
  const [eligibilityErrors, setEligibilityErrors] = useState<string[]>([]);
  const [customerIdFromEnergyBill, setCustomerIdFromEnergyBill] =
    useState<string>("");
  const [storedSocialContract, setStoredSocialContract] = useState<string>("");
  const [energyBillAnalysisResult, setEnergyBillAnalysisResult] =
    useState<EnergyBillAnalysis | null>(null);
  const [documentsProgress, setDocumentsProgress] = useState<number>(0);
  const [needManualAnalysis, setNeedManualAnalysis] = useState<boolean>(false);

  return (
    <GlobalDataContext.Provider
      value={{
        socketId,
        setSocketId,
        accountType,
        setAccountType,
        customer,
        setCustomer,
        isLoading,
        setIsLoading,
        averageConsumption,
        setAverageConsumption,
        formState,
        setFormState,
        billElegibility,
        setBillElegibility,
        globalEnergyBill,
        setGlobalEnergyBill,
        currentStep,
        setCurrentStep,
        eligibilityErrors,
        setEligibilityErrors,
        customerIdFromEnergyBill,
        setCustomerIdFromEnergyBill,
        storedCnpjCard,
        setStoredCnpjCard,
        storedSocialContract,
        setStoredSocialContract,
        energyBillAnalysisResult,
        setEnergyBillAnalysisResult,
        documentsProgress,
        setDocumentsProgress,
        needManualAnalysis,
        setNeedManualAnalysis,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};
